<div
  class="results-value"
  (click)="onItemClick()"
  [class.selected]="isItemSelected"
>
  <div class="result-item">
    <div class="location-icon-container">
      <div [style.background]="locationType?.color" class="icon">
        <mat-icon
          fontSet="icon"
          [style.color]="locationType?.color"
          [fontIcon]="locationType?.icon"
        ></mat-icon>
      </div>
    </div>
    <div class="result-desc">
      <div class="result-title" [innerHTML]="locationName"></div>
    </div>
  </div>
</div>
