import { Component } from '@angular/core';
import { AuthService } from '@fc-core';

@Component({
  selector: 'fc-hijacked-user-info',
  template: `
    <div class="hijacked-info">
      <p>
        ️<strong
          >WARNING️ ️️- Acting as {{ authService.tokenData.email }}</strong
        >
      </p>
      <button (click)="releaseHijack()" mat-icon-button>
        <mat-icon fontIcon="icon-exit_to_app">exit_to_app</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .hijacked-info {
        background-image: linear-gradient(
          45deg,
          #116175 25%,
          #113345 25%,
          #113345 50%,
          #116175 50%,
          #116175 75%,
          #113345 75%,
          #113345 100%
        );
        background-size: 56.57px 56.57px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 24px;
        height: 32px;
        border-bottom: 2px solid #fff800;
      }
    `,
  ],
})
export class HijackedUserInfoComponent {
  constructor(public authService: AuthService) {}

  releaseHijack() {
    this.authService.releaseHijack();
  }
}
