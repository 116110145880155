<div class="notification-container">
  <div class="notification-header">
    <div class="title h2">Notifications</div>
    <div class="actions">
      <button mat-icon-button (click)="notificationClose.emit()">
        <mat-icon fontIcon="icon-clear"></mat-icon>
      </button>
    </div>
  </div>
  <mat-tab-group [mat-stretch-tabs]="false">
    <mat-tab label="Events">
      <ng-template mat-tab-label>Events</ng-template>
      <fc-events-tab></fc-events-tab>
    </mat-tab>
    <mat-tab label="News">
      <ng-template mat-tab-label>News</ng-template>
      <fc-news-tab></fc-news-tab>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="overlay" (click)="notificationClose.emit()"></div>
