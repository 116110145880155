<div class="search-input">
  <mat-icon fontIcon="icon-search" class="search-icon"></mat-icon>
  <input
    type="text"
    placeholder="Search"
    [(ngModel)]="searchInput"
    #searchInputRef
  />
  <div
    [matMenuTriggerFor]="menu"
    #categorySelect="matMenuTrigger"
    class="search-select"
    [class.active]="categorySelect.menuOpen"
  >
    <span>{{ categorySelected.name }}</span>
    <fc-icon class="category-icon" icon="icon-arrow_drop_down"></fc-icon>
  </div>
  <div>
    <mat-menu #menu="matMenu">
      <div class="category-items">
        <ng-container *ngFor="let category of categories">
          <div
            *ngIf="category !== categorySelected"
            (click)="selectCategory(category)"
            class="category-item"
          >
            <span class="category-name">{{ category.name }}</span>
          </div>
        </ng-container>
      </div>
    </mat-menu>
  </div>
</div>
