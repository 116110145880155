import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@fc-core/services/permissions.service';
import { PermissionState } from '@fc-core';

@Component({
  selector: 'fc-developer-options',
  template: `
    <div class="header">
      <h2 mat-dialog-title>Developer options</h2>
      <div class="close">
        <button mat-icon-button mat-dialog-close>
          <mat-icon fontIcon="icon-clear"></mat-icon>
        </button>
      </div>
    </div>
    <mat-dialog-content>
      <div class="options">
        <mat-slide-toggle
          [checked]="permission.active"
          [(ngModel)]="permission.active"
          *ngFor="let permission of permissions"
        >
          {{ permission.name }}
        </mat-slide-toggle>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions class="align-end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        (click)="savePermissions()"
        color="accent"
        mat-raised-button
        mat-dialog-close
      >
        Save
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .options {
        display: flex;
        flex-direction: column;
        padding: 20px;
      }
    `,
  ],
})
export class DeveloperOptionsComponent implements OnInit {
  permissions: PermissionState[];

  constructor(private permissionsService: PermissionsService) {}

  ngOnInit() {
    this.permissions = [...this.permissionsService.userPermissions];
  }

  savePermissions() {
    this.permissionsService.updatePermissions(this.permissions);
    location.assign('/map');
  }
}
